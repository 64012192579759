import React from "react";

// import InfoImage1 from "../../Assets/images/about/info-image-1.svg";
// import InfoImage from "../../Assets/images/about/info-image.svg";
import whoWeAreImg from "../../Assets/images/about/team_intelsense_ai_.jpg";
import WorkImage from "../../Assets/images/about/work.webp";
import Meta from "../../components/Meta/Meta";
import "./About.scss";
import HistoryTimeline from "../../components/HistoryTimeline/HistoryTimeline";


const About = () => {

  return (
    <>
      <Meta title={"About US"} />
      <section className="about-wrapper">
        {/* HERO SECTION */}
        <section>
          <div className="aboutus-intro d-flex align-items-center justify-content-center">
            <h2 className="fw-bold text-white text-center">
            Building Voice & Generative AI Solutions for the Next Billion Users
            </h2>
          </div>
        </section>

        {/* WHO WE ARE */}
        <section>
          <div className="col-12 background-E2F0F6 text-dark px-4 py-4">
            <div className="container who-we-are-container">
              <div className="row py-5">
              <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                  <div className="">
                  <h1 className="pb-5 text-center fw-bold">WHO WE ARE</h1>
                    <img className="img-fluid" src={whoWeAreImg} alt="" />
                  </div>
                </div>
                <div className="col-12 col-md-12 py-5 text-center">
                  
                  <p className="fs-5  mx-auto">
                  Intelsense AI started as a plan to bridge the gap of inconsistent and asymmetric access to linguistic tools across the world. Starting from education to job fields, all sorts of speech-based technologies are available in English only or mostly which deprives the majority of the native population in every country. After 4 years, Intelsense has evolved into a thriving VoiceTech and Generative AI company with over 30 dedicated employees. Together, we are committed to building a more inclusive world where language no longer acts as a barrier to accessing AI technologies.</p>
                  <p className="fs-5 mx-auto">
                  We believe every product and service should be voice-enabled and every person should be able to interact naturally with the products around them simply by talking. We live in an era where we are interacting with machines more than humans. In the last decade, the degree of technological change that has happened is beyond imagination. We now talk to our cars, screens, and speakers, we talk to our television. But have you ever wondered how these machines understand human language? That is exactly what we do at Intelsense. At Intelsense, we're building Voice AI and Generative AI products that make it easier for people to engage with the world. We build enterprise Voice and Generative AI products and also provide customized services to help clients reach their organizational goals and objectives.
                  </p>
                </div>
                
              </div>
              <hr />
              {/* <div className="row py-5">
                <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                  <h1>
                    OUR BELEIVE
                  </h1>
                </div>
                <div className="col-12 col-sm-6">
                  <p>
                  We believe every product and service should be voice-enabled and every person should be able to interact naturally with the products around them simply by talking. We live in an era where we are interacting with machines more than humans. In the last decade, the degree of technological change that has happened is beyond imagination. We now talk to our cars, screens, and speakers, we talk to our television. But have you ever wondered how these machines understand human language? That is exactly what we do at Intelsense. At Intelsense, we're building Voice AI and Generative AI products that make it easier for people to engage with the world. We build enterprise Voice and Generative AI products and also provide customized services to help clients reach their organizational goals and objectives.
                  </p>

                </div>
              </div> */}
              {/* <hr /> */}
              <div className="row py-5">
              <div className="col-12 col-sm-6">
                  <p>
                  Our primary focus is to make Generative AI relevant to Bangladesh, with immediate attention given to training AI models to support the Bangladeshi language and voice-first interfaces. Additionally, we are collaborating with Bangladeshi enterprises to co-build domain-specific AI models using their data to enhance workflow and productivity. Our focused domain are, Large Language Model (LLM), Automatic Speech Recognition (ASR), Text-to-Speech (TTS) and Natural Language Processing (NLP). 
                  </p>
                  

                </div>
                <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                  <h1>
                    OUR FOCUS
                  </h1>
                </div>
                
              </div>
              <hr />
              <div className="row py-5">
                <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                  <h1>
                    OUR MISSION
                  </h1>
                </div>
                <div className="col-12 col-sm-6">
                  <p>
                  At Intelsense, our mission is to build Voice AI and Generative AI solutions tailored for the next billion users, leveraging advanced AI research to revolutionize communication and interaction with state-of-the-art voice enabled technologies. Intelsense was founded with a mission to democratize the
                    world’s access to cutting-edge language (NLP) and Speech
                    Processing technology. 90% of Speech & NLP solutions are
                    exclusively available in European languages. In Bangladesh,
                    most of the people are not fully familiar with these
                    languages and most technologies are not available in bangla.
                    We want to break down the language barriers encountered by
                    people in Asia, the Middle-East and Africa by enabling 6
                    billion people to access the internet in the language and
                    mode of their choice.
                  </p>
                  <p>
                    At Intelsense, we are committed to facilitating an inclusive
                    world in which people’s access to technology is not limited
                    by their native language. Our team looks to find out how
                    voice tech can evolve the way business operations at
                    consumer level can be transformed into a matter of seconds
                    and data efficiency. Using Ai can significantly change how
                    e-commerce and banking actions look like at present, making
                    them more convenient.
                  </p>

                </div>
              </div>
              {/* <hr /> */}
            </div>
          </div>
        </section>

        {/* INFO SECTION */}
        <section className="info-wrapper-sention-1 white py-sm-5">
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 col-sm-6 pe-sm-5">
              <p>
                Our team comprises 31 highly skilled members, including Speech Scientists, AI engineers, DevOps Engineers, Backend and Frontend Developers, data analytics engineers, product engineers, product designers, and leads, as well as seven Co-founders. Our track record speaks volumes, with clients experiencing a remarkable return on investment on AI.
                </p>
                <p>Moreover, our commitment to nurturing talent is evident, with over 35 fresh graduates kickstarted their careers in AI engineering and VoiceTech domains with us. Many of these individuals have gone on to pursue further education or secure positions in leading tech companies worldwide.</p>
                
                
              </div>
              <div className="col-12 col-sm-6 ps-sm-5">
              <p>
                Key achievements include delivering 07 AI products, deploying 21 AI models and algorithms, and establishing partnerships and ongoing operations in four countries, including Bangladesh, UK, USA and UAE. Notable clients include Walton, Brac Bank, and Scitech.

                </p>
               
              </div>
            </div>
          </div>
        </section>
        {/* INFO SECTION */}
        {/* <section className="info-wrapper-sention-1 white py-sm-5">
          <div className="container pt-5">
            <div className="row">
              <div className="col-12 col-sm-6 pe-sm-5">
                <h3 className="sub-heading base-color fw-bold fs-3 text-sm-start mb-3 pb-2 mb-sm-0">
                  OUR STORY
                </h3>
                <p>
                  Intelsense AI was founded with a vision to revolutionize the
                  way businesses operate by leveraging the power of artificial
                  intelligence. Our journey began with a small group of AI
                  enthusiasts 4 years ago, who shared a common passion for
                  developing innovative solutions that could make a real
                  difference in the world.
                </p>
                <p>
                  Over the years, our team has grown, and so has our expertise.
                  Today, we are proud to have developed some of the most
                  advanced AI solutions in the market, helping businesses to
                  streamline their operations, increase productivity, and drive
                  growth.
                </p>
              </div>
              <div className="col-12 col-sm-6 ps-sm-5 mt-5">
                <img
                  src={InfoImage}
                  alt="info_Image"
                  className="d-block mx-auto me-sm-0 mb-3"
                />
                <h3 className="sub-heading base-color fw-bold  text-sm-end mb-3 pb-1 mb-sm-0">
                  WHAT WE DO
                </h3>
                <p>
                  Intelsense.ai provides AI services, solutions, and products to
                  help organizations across different industries to implement AI
                  today. Our AI research and development facilities in
                  Bangladesh, US and Canada allow us to solve today’s complex
                  problems, customize real-life AI applications for positive
                  future impact, and drive future innovation.
                </p>
              </div>
            </div>
          </div>
        </section> */}

        {/* Our purpose section */}
        {/* <section className="info-wrapper-sention-2 white py-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 pe-sm-5">
                <h3 className="sub-heading base-color fw-bold fs-3 text-sm-start mb-3 pb-2 mb-sm-0">
                  OUR PURPOSE
                </h3>
                <p>
                  At Intelsense AI, our purpose is to empower businesses with
                  the latest AI technology so that they can thrive in the
                  digital age. We believe that speech-based AI has the potential
                  to transform the way businesses operate and help them achieve
                  their full potential. We are committed to developing AI
                  solutions that are accessible, easy to use, and can be
                  integrated seamlessly into any existing business processes.
                </p>
                <p>
                  We believe in putting our customers first and working closely
                  with them to understand their unique needs and challenges. By
                  doing so, we can create customized solutions that address
                  their specific pain points and help them achieve their
                  business goals. We aim to simplify the communication process
                  as much as possible and increase the efficient extraction of
                  information.
                </p>
              </div>
              <div className="col-12 col-sm-6 ps-sm-5 mt-5">
                <img
                  src={InfoImage}
                  alt="info_Image"
                  className="d-block mx-auto me-sm-0 mb-3"
                />
                <h3 className="sub-heading base-color fw-bold  text-sm-end mb-3 mb-sm-0 pb-1">
                  DRIVEN TO WIN
                </h3>
                <p>
                  W're solving a hard problem that requires incredible focus and
                  commitment. Success means doing a few things differently.
                </p>
              </div>
            </div>
          </div>
        </section> */}

        {/* COMPANY HISTORY SECTION */}
        <section className="history-snap-scroll base-gradient-background">
          <div className="container py-5">
            <h2 className="pt-5 fw-bold text-center  text-white">HISTORY TIMELINE</h2>
            <HistoryTimeline />
          </div>
        </section>

        {/* WORK SECTION */}

        <section className="work-wrapper white py-5">
          <div className="container-lg">
            <div className="row pb-5">
              <div className="col-12 col-md-4 pe-sm-3 mt-5">
                {/* <img
                  src={InfoImage1}
                  alt="info_Image"
                  className="d-block mx-auto mx-sm-0 mb-3 info-image"
                /> */}
                <h3 className="sub-heading base-color fw-bold fs-3 text-sm-start mb-3 pb-2 mb-sm-0">
                  OUR STORY
                </h3>
                <p>
                  Intelsense AI was founded with a vision to revolutionize the way businesses operate by leveraging the power of artificial intelligence. Our journey began with a small group of AI enthusiasts 4 years ago, who shared a common passion for developing innovative solutions that could make a real difference in the world.
                </p>
                <p>
                  Over the years, our team has grown, and so has our expertise. Today, we are proud to have developed some of the most advanced AI solutions in the market, helping businesses to streamline their operations, increase productivity, and drive growth.
                </p>
              </div>
              <div className="col-12 col-md-4 px-5 px-md-0 pt-4 pt-md-0">
                <div className="px-sm-5 px-md-0">
                  <img
                    src={WorkImage}
                    className="img-fluid"
                    alt="middle_image"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 ps-sm-3 mt-5">
                {/* <img
                  src={InfoImage}
                  alt="info_Image"
                  className="d-block mx-auto me-sm-0 mb-3 info-image"
                /> */}
                <h3 className="sub-heading base-color fw-bold  text-sm-end mb-3 mb-sm-0 pb-1">
                  WHAT WE DO
                </h3>
                <p>
                  At Intelsense AI, we are dedicated to revolutionizing the potential of enterprises through cutting-edge Voice AI and machine learning solutions. Our expertise lies in speech recognition and natural language processing (NLP). By leveraging these powerful tools, we enable businesses to unlock new possibilities and optimize their operations. From our state-of-the-art SenseVoice speech-to-text engine to our AI-powered virtual assistant, SenseBots, we provide comprehensive solutions for voice-based AI, conversational chatbots and much more.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="work-wrapper white py-5">
          <div className="container-lg">
            <div className="row pb-5">
              <div className="col-12 col-md-4 pe-sm-3">
                <img
                  src={InfoImage1}
                  alt="info_Image"
                  className="d-block mx-auto mx-sm-0 mb-3 info-image"
                />
                <h3 className="sub-heading base-color fw-bold fs-3 text-sm-start mb-3 pb-2 mb-sm-0">
                  AREA OF OUR WORK
                </h3>
                <p>
                  Composition of Machine Learning and Deep Learning Techniques
                  in Computer Vision, Natural Language Processing
                  productionalizing, Intelligence Chatbots. Domain Specific/Open
                  Domain specific Recognition System . Virtual Assistance and
                  Internet of things.
                </p>
              </div>
              <div className="col-12 col-md-4 px-5 px-md-0 pt-4 pt-md-0">
                <div className="px-sm-5 px-md-0">
                  <img
                    src={WorkImage}
                    className="img-fluid"
                    alt="middle_image"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 ps-sm-3 mt-5">
                <img
                  src={InfoImage}
                  alt="info_Image"
                  className="d-block mx-auto me-sm-0 mb-3 info-image"
                />
                <h3 className="sub-heading base-color fw-bold  text-sm-end mb-3 mb-sm-0 pb-1">
                  LEADING THE MOVEMENT
                </h3>
                <p>
                  We're a team with a multitude of experience and perspective.
                  With decades of engineering, science, and domain expertise,
                  we're incorporating our best practices to create a company for
                  the long-term and building AI driven products that will
                  deliver the benefits to all of humanity.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </section>
    </>
  );
};

export default About;
