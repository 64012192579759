import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/HOME/logo.svg";
import "./Header.scss";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <>
      <header className="header-wrapper py-3 top-sticky shadow-sm sticky-top">
        <nav className="navbar navbar-expand-lg">
          <div className="container-xxl d-flex justify-content-between align-items-center">
            {/* logo */}
            <Link to="/">
              <img src={Logo} className="navbar-brand" alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* navigations */}
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav ms-auto gap-0 gap-lg-3 ps-sm-3 d-flex align-items-center align-items-sm-start align-items-lg-center">
                <Link to="/" className="nav-link fw-bold" aria-current="page">
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Home
                  </span>

                </Link>
                <div className="nav-link" aria-current="page">
                  <div className="dropdown">
                    <p
                      className="dropdown-toggle mb-0 fw-bold"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Products
                    </p>
                    <ul className="dropdown-menu submenu border-none">
                      <li className="item">
                        <Link
                          className="text-black"
                          to="/products"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Overview
                          </span>

                        </Link>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/products/sense.ai"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            SenseAI
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/products/sense-speech"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            SenseSpeech
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <Link
                          to="https://sensevoice.intelsense.ai/"
                          target="_blank"
                          className="text-black"
                        >
                          Sensevoice
                        </Link>
                      </li>
                      
                      <li className="item">
                        <HashLink
                          to="/products/#sense-bots"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Sensebots
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/products/#hia"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            HIA
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/products/#air-voice"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Airvoice
                          </span>

                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nav-link" aria-current="page">
                  <div className="dropdown">
                    <p
                      className="dropdown-toggle mb-0 fw-bold"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </p>
                    <ul className="dropdown-menu submenu border-none">
                      <li className="item">
                        <HashLink
                          className="text-black"
                          to="/services/#speech-text"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Speech to Text
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/services/#language-understanding"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Language Understanding
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/services/#entity-recognition"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Entity Recognition
                          </span>

                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/services/#text-speech"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Text to Speech
                          </span>

                        </HashLink>
                      </li>

                      <li className="item">
                        <HashLink
                          to="/services/#machine-translation"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Machine Translation
                          </span>
                        </HashLink>
                      </li>
                      <li className="item">
                        <HashLink
                          to="/services/#translitration"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Transliteration
                          </span>
                        </HashLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nav-link" aria-current="page">
                  <div className="dropdown">
                    <p
                      className="dropdown-toggle mb-0 fw-bold"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        Company
                      </span>

                    </p>
                    <ul className="dropdown-menu submenu border-none">
                      <li className="item">
                        <Link
                          to="/about"
                          className="text-black"
                        >
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            About Us
                          </span>

                        </Link>
                      </li>
                      <li className="item">
                        <Link to="/leadership" className="text-black">
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Leadership
                          </span>

                        </Link>
                      </li>
                      <li className="item">
                        <Link to="/career" className="text-black">
                          <span
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            Careers
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <Link
                  to="/investor-relation"
                  className="nav-link fw-bold"
                  aria-current="page"
                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Investors Relation
                  </span>

                </Link>
                <Link
                  to="/blog"
                  className="nav-link fw-bold"
                  aria-current="page"

                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Blogs
                  </span>

                </Link>
                <Link
                  to="/contact"
                  className="nav-link fw-bold"
                  aria-current="page"
                >
                  <span
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Contact
                  </span>

                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
