import React from "react";

import "./Service.scss";
import speechTextImg from "../../Assets/images/HOME/services/services-easy1.png";
import NLUImg from "../../Assets/images/HOME/services/services-easy2.png";
import Meta from "../../components/Meta/Meta";

const Service = () => {
  return (
    <>
      <Meta title={"Services"} />
      <section className="service-top"></section>
      <section className="service-page">
        <div className="container p-md-5 service-container">
          <div className="py-5">
            <h1 className="pb-5">OUR SERVICES</h1>
            <p className="w-50">
              The most accurate speech and text APIs, customizable with a click
              of a button using &nbsp;
              <span className="base-color">Intelsense</span>&nbsp;services.
            </p>
          </div>
          <div className="row">
            <hr />
            <div id="speech-text" className="col-12 py-5">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h3 className="pb-md-4">Speech To Text</h3>
                  <p>
                    IntelsenseAI’s ASR converts speech into text , utilizing
                    patented approaches in neural network technology for
                    transcriptions of audio from a variety of sources and dozens
                    of languages and dialects like narrowband phone calls, and
                    wideband media-broadcast content with precision similar to
                    human ears.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <div>
                    <img className="img-fluid" src={speechTextImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div id="language-understanding" className="col-12 py-5">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div>
                    <img className="img-fluid" src={NLUImg} alt="" />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h3 className="pb-md-4">Language Understanding</h3>
                  <p>
                    IntelsenseAI’s NLU technology seamlessly analyzes and
                    extracts valuable context and “meaning” from documents,
                    recordings of conversations, utilizing deep neural network
                    and machine learning technologies.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div id="text-speech" className="col-12 py-5">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h3 className="pb-md-4">Text-to-Speech</h3>
                  <p>
                    IntelsenseAI’s neural text-to-speech (TTS) technologies
                    produce a naturally synthesized speech with high-quality
                    voice offered in a wide variety of voices and languages,
                    that makes it sound like an actual human mouth in action. It
                    can also create custom voices from your own recordings for
                    your brand or consumer-based interfaces.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <div>
                    <img src={speechTextImg} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div id="entity-recognition" className="col-12 py-5">
              <div className="row">
                <div className="col col-md-6">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Input:</strong> Text
                      </p>
                      <p className="border text-center">
                        I would love to purchase 5 kilograms of rice from
                        Chaldal right now
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Output</strong>
                      </p>
                      <p className="border p-1">
                        Product Name: Rice
                        <br />
                        Quantity: 5 Kilograms
                        <br />
                        Company: Chaldal
                        <br />
                        Time: Immediate
                        <br />
                        Sentiment: Positive
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h3 className="pb-md-4">Entity Recognition</h3>
                  <p>
                    Smoothly extract information from unstructured text and
                    classify it into predefined categories (names, addresses,
                    medicines, etc.) with our state-of-the-art Entity
                    Recognition models. Get a custom solution for your unique
                    use-case.
                  </p>
                </div>
              </div>
            </div>

            <hr />

            <div id="machine-translation" className="col-12 py-5">
              <div className="row py-md-4">
                <div className="col col-md-6">
                  <h3 className="pb-md-4">Machine Translation</h3>

                  <p>
                    Get automatic and accurate translations in the interface of
                    your choice through a single API. You can tailor your models
                    for custom domains such as finance, legal or medical. Over
                    100 langauges supported.
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Input:</strong> Text
                      </p>
                      <p className="border text-center p-1">
                        আপনার ভাষা এবং প্রশ্ন বোঝার জন্য আমরা মেশিন লার্নিং প্রযুক্তি ব্যবহার করি
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Output: </strong>Translated text
                      </p>
                      <p className="border p-1 text-center">
                        We use machine learning technology to understand your language and questions
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div id="translitration" className="col-12 py-5">
              <div className="row py-md-4">
                <div className="col-12 col-md-6">
                  <div className="row justify-content-center flex-column align-items-center">
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Input:</strong> Text
                      </p>
                      <p className="border text-center p-1">
                        আমরা আমাদের ভয়েস প্রযুক্তির মাধ্যমে মানুষের জীবনকে সহজ করে তুলি
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p>
                        <strong>Output: </strong>Translitarated text
                      </p>
                      <p className="border p-1 text-center">
                      Amra amader voice projuktir maddhome manusher jibonke sohoj kore tuli
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col col-md-6">
                  <h3 className="pb-md-4">Transliteration</h3>
                  <p>
                    Convert written text from one alphabet to another using our
                    pre-trained production-ready Transliteration models. Get
                    custom transliteration models with your own data for any
                    language.
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
