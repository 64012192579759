import { BsDatabaseLock } from "react-icons/bs";
import { ImCalculator } from "react-icons/im";
import { FaLanguage } from "react-icons/fa";

export const whyIntelSenseData = [
  {
    icon: <ImCalculator />,
    reason: "Accuracy",
    description: `The most accurate speech and text APIs for locally spoken languages in Asia, the Middle East and Africa.`,
  },
  {
    icon: <FaLanguage />,
    reason: "Languages",
    description: `With our products regional users get multilingual support with the most advanced voice processing technology.`,
  },
  {
    icon: <BsDatabaseLock />,
    reason: "Data Privacy",
    description: `Maintaining data confidentiality is our top priority for B2B and B2C services across all platforms.`,
  },
];
